
.container
{
  width: 200px;
  color: #717275;
  background-color: white;
}

.content
{
  border: 1px solid #83858b;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.rotate
{
  transform: rotate(180deg);
  transition: all 300ms;
}

.dropdownContentHide
{
  height: 0px;
  overflow: hidden;
}

.dropdownContent
{
  width: 200px;
  background-color: #fff;
  border: 1px solid #83858b;
  border-radius: 5px;
  transition: all 300ms;
  margin-top: 5px;
  position: absolute;
}

.dropdownContent > ul
{
  padding: 0;
  margin: 0;
}

.dropdownContent > ul > li
{
  list-style: none;
  margin-top: 2px;
  padding: 5px 20px;
  border-radius: 5px;
  transition: all 300ms;
  cursor: pointer;
}

.dropdownContent > ul > li:hover
{
  background-color: rgba(202, 202, 202, 0.2);
}