
.content
{
    display: grid;
    grid-template-columns: 70% 1fr;
    align-items: start;
    column-gap: 20px;
}

.cursor_pointer
{
    cursor: pointer;
}

.circle
{
    max-width: 250px;
    margin-top: 80px;
}


@media screen and (max-width: 992px) 
{
    .content
    {
        grid-template-columns: repeat(1, auto);
        /* align-items: start; */
        column-gap: 0;
        background-color: red;
    }

    .circle
    {
        margin-top: 30px;
    }
}
