
.cursor_pointer
{
    cursor: pointer;
    transition: all 300ms;
}

.cursor_pointer:hover
{
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3) !important;
}