
.container
{
    /* position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    background-color: rgba(0, 0, 0, 0.5);
}

.imgContainer
{
    display: inline-block;
    max-width: 380px;
}

.img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}