
*
{
  user-select: none;
}
.navbar-nav .nav-link
{
  width: 100%;
}

.nav-custom-btn
{
  outline: none;
  border: none;
  background-color: transparent;
}
.nav-custom-btn:hover
{
  background-color: rgba(246, 81, 41) !important;
  color: white !important;
}

.hero-slide > .backstretch 
{
  width: 100% !important;
  left: unset !important;
  top: 0 !important;
}

.hero-slide > .backstretch > img
{
  object-fit: contain !important;
  object-position: top !important;
  width: 100% !important;
  left: unset !important;
  top: 0 !important;
}

.hero-form
{
  background-color: rgba(255, 255, 255, 0.3);
}

.hero-section
{
  background-color: #f9f9f9 !important;
}
.carousel-rtl
{
  display: block !important;
}
.carousel-rtl .owl-nav
{
  right: unset;
  left: 0;
}

@media print {
  body * {
    visibility: hidden;
    height: 0;
  }
}