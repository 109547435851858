.languages
{
  height: 30px;
  background-color: rgba(233, 88, 69, 0.2);
  width: 100%;
}
.grid2
{
  display: grid;
  grid-template-columns: repeat(2, auto);
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 10px;
}
.profileContainer
{
  height: 30px;
  max-width: 300px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: flex-start;
}
.profile
{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}
.name
{
  margin: 0;
  text-wrap: nowrap;
}
.btns
{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 5px;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding-top: 4px;
}
.langButton
{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: transparent;
  padding: 0 5px;
  outline: unset;
  border: 0;
  transition: 300ms;
}
.langButton:hover, .langButton.activeBTN
{
  color: #eb0028;
}

.modal
{
  display: grid;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-content: center;
  justify-content: center;
}

.loginWrapper
{
  background-color: white;
  width: 310px;
  height: calc(100% + 20px);
  border-radius: 5px;
  display: grid;
  padding: 15px;
  align-content: start;
}
.wrapperHeader
{
  font-size: 24px;
  justify-self: end;
  color: #eb0028;
  cursor: pointer;
  align-self: start;
}
.note
{
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
}
.mediaButton {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid transparent;
}

.mediaText {
  margin-left: 10px;
  align-self: center;
}

.mediaIcon
{
  display: flex;
  align-items: center;
}
.buttons
{
  display: grid;
  row-gap: 5px;
  align-content: start;
}
.googleButton {
  background-color: #eb0028;
  border-color: #eb0028;
  transition: 300ms;
}
.googleButton:hover {
  background-color: transparent;
  color: #eb0028;
}

.facebookButton {
  background-color: #1877f2;
  border-color: #1877f2;
  transition: 300ms;
}
.facebookButton:hover {
  background-color: transparent;
  color: #1877f2;
}
@media screen and (max-width: 375px)
{
  .name
  {
    width: 100px;
    overflow: hidden;
  }
}