
.cursor_pointer
{
    cursor: pointer;
    transition: all 300ms;
}

.cursor_pointer:hover
{
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3) !important;
}

.update:hover
{
    box-shadow: 2px 2px 8px rgba(20, 20, 20, 0.5);
    transition: all 300ms;
}

.form > input
{
    margin: 10px 0;
    max-width: 500px;
}