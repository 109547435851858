
.container
{
    background-color: rgba(0, 0, 0, 0.5);
}

.form > input
{
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 5px;
    border-width: 1px;
}

.cDropdown
{
    width: 100% !important;
    padding: 0;
}

.cDropdownContent
{
    width: 96% !important;
}