
.cursor_pointer
{
    position: relative;
    cursor: pointer;
    transition: all 300ms;
}

.cursor_pointer:hover
{
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3) !important;
}

.trashContainer
{
    /* width: fit-content; */
    position: absolute;
    top: 20%;
    right: 15px;
    /* z-index: 99 !important; */
    cursor: pointer;
}

.cashBtn:hover
{
    box-shadow: 2px 2px 8px rgba(20, 20, 20, 0.5);
    transition: all 300ms;
}

.paginationContainer
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    gap: 5px;
}

.paginationContainer > li
{
    list-style: none !important;
}

.paginationContainer .pageNum
{
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 400;
}

.paginationContainer .pageNum:hover
{
    background-color: rgb(220, 53, 69);
    color: white;
}

.paginationContainer .activePagi
{
    background-color: rgb(220, 53, 69);
    color: white;
}


@media screen and (max-width: 765px)
{
    .trashContainer
    {
        top: 35%;
    }
    .paginationContainer
    {
        font-size: 10px !important;
    }
}