.contentWrapper
{
  display: grid;
  justify-content: center;
  row-gap: 15px;
  margin: 30px 0;
  justify-items: center;
  text-align: center;
  padding-top: 190px;
  padding-bottom: 50px;
}

.loader
{
  border: 4px solid white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-bottom-color: transparent;
  position: relative;
  animation-name: rotate360 ;
  animation-duration: 2000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  text-align: center;
}



.child
{
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    left: -5px;
    bottom: -5px;
    border: 7px solid transparent;
    border-top-color: black;
    transition: 300ms;
    animation-name: rotate ;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    
}

.child::before
{
    content: '';
    display: inline-block;
    position: absolute;
    top: -7px;
    right: -7px;
    left: -7px;
    bottom: -7px;
    /* border: 5px solid rgba(216, 206, 206, 0.05); */
    border: 5px solid rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    
}

@keyframes rotate
{
  0%
  {
    transform: rotate(-90deg);
  }
  100%
  {
    transform: rotate(90deg);
  }
}

@keyframes rotate360
{
  0%
  {
    transform: rotate(0);
  }
  100%
  {
      
    transform: rotate(360deg);
  }
}