
.backContainer
{
    width: fit-content;
    padding: 6px 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    border-radius: 50%;
    background-color:rgba(0, 0, 0, 0.03);
    transition: all 300ms;
}

.backContainer:hover
{
    background-color:rgba(0, 0, 0, 0.08);
}

.shareContainer
{
    width: fit-content;
    /* padding: 5px 10px; */
    position: absolute;
    top: 10px;
    right: 0px;
    cursor: pointer;
    border-radius: 20%;
    transition: all 300ms;
}

.shareContainer > p
{
    font-size: 17px;
}

.shareContainer > p:hover
{
    color: rgb(189, 20, 37) !important;
}

@media screen and (max-width: 350px)
{
    .shareContainer > p
    {
        font-size: 14px;
    }   
}