.text
{
  text-align: center;
  max-width: 700px;
  padding: 10px;
}

.text p
{
  font-size: 18px;
}

.drawImg
{
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.drawImg img
{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.animatingTokens
{
  position: absolute;
  max-width: 200px;
  width: 100%;
  height: 60px;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: red;
  border: 3px inset red;
  border-radius: 5px;
}
.scalling::before
{
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 3px solid white;
  /* border-right-color: transparent;
  border-left-color: transparent; */
  /* border-radius: 5px; */
}
.scalling::before
{
  animation-name: scaling;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: linear;
}
.animatingTokens > div
{
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}
.animatingTokens p:first-child
{
  margin-top: 15px;

}
.animatingTokens p
{
  font-size: 32px;
  margin: 0;
  color: white;
}

.top
{
  animation-name: top;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.luckyToken
{
  color: white;
  padding: 10px;
  border: 1px solid white;
  font-size: 22px;
  font-weight: bold;
}

.time
{
  background-color: #eb0028;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
}

.time p
{
  color: white;
  font-size: 28px;
  margin: 0;
}


@keyframes top {
  0%
  {
    transform: translateY(0);
  }
  100%
  {
    transform: translateY(calc(-100% + 40px));
  }
}

@keyframes scaling {
  0%
  {
    transform: scale(1);
    opacity: 0.8;
  }
  100%
  {
    opacity: 1;
    transform: scale(1.1);
  }
}


@media all and (max-width: 767px) {
  .animatingTokens p
  {
    font-size: 24px;
  }
  .animatingTokens p:first-child
  {
    margin-top: 10px;
  }
  .animatingTokens
  {
    max-width: 160px;
    width: 100%;
    height: 50px;
  }
}

@media all and (max-width: 600px) {
  .scalling::before
  {
    border: 2px solid white;
  }
  .animatingTokens
  {
    border: 2px inset red;
  }
  .animatingTokens p
  {
    font-size: 20px;
  }
  .animatingTokens p:first-child
  {
    margin-top: 4px;
  }
  .animatingTokens
  {
    max-width: 140px;
    width: 100%;
    height: 40px;
  }
}

@media all and (max-width: 425px) {
    .time p
  {
    font-size: 20px;
  }
  .animatingTokens p
  {
    font-size: 16px;
  }
  .animatingTokens p:first-child
  {
    margin-top: 3px;
  }
  .animatingTokens
  {
    max-width: 100px;
    width: 100%;
    height: 40px;
  }
  .drawImg
  {
    height: 450px;
  }
}

@media all and (max-width: 320px) {
  .animatingTokens p
  {
    font-size: 14px;
  }
  .animatingTokens p:first-child
  {
    margin-top: 2px;
  }
  .animatingTokens
  {
    max-width: 85px;
    width: 100%;
    height: 35px;
  }
  .drawImg
  {
    height: 370px;
  }
}