
.container
{
    visibility: hidden;
}

.nameContainer
{
    margin-top: 100px;
}

.nameContainer > p
{
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    text-align: center;
}


@media print {
    .container, .container * {
      visibility: visible !important;
      height: auto;
    }
}